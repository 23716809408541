import { Badge } from "@atoms/badge";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { Table } from "@components/table";
import { useDashboard } from "@features/dashboard/state/hooks";
import {
  TransactionFiltersAtom,
  TransactionsQueryInputAtom,
} from "@features/kyt/state/store";
import { useTransactions } from "@features/kyt/state/use-transactions";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { useHasAccess } from "@features/auth/state/use-access";
import { TransactionEditionModalAtom } from "./edition-modal";
import { KytTransactionsListFilter } from "./filters";
import { Pagination } from "@components/table/table";
import { availableColumns } from "./columns";
import { KytTransaction } from "@features/kyt/types";
import { useAssignments } from "@features/assignments/state/use-assignment";

export default function LiveTransactionsPage() {
  const hasAccess = useHasAccess();
  const { kyt } = useDashboard();

  const [filters] = useRecoilState(TransactionFiltersAtom);
  const { transactions, fetchTransactions, loading } = useTransactions();
  const [, setTransactionModal] = useRecoilState(TransactionEditionModalAtom);
  const [advancedQuery, setAdvancedQuery] = useRecoilState(
    TransactionsQueryInputAtom
  );
  const columns = [
    "external_id",
    "from",
    "to",
    "amount",
    "date",
    "context",
    "score",
    "status",
    "actions",
  ];

  useAssignments(
    "transaction",
    transactions?.data?.map((transaction) => transaction.id) || []
  );

  const renderColumns = availableColumns;

  return (
    <Page>
      {hasAccess("KYT_MANAGE") && (
        <Button
          className="float-right"
          theme="primary"
          onClick={() =>
            setTransactionModal({
              open: true,
              transaction: undefined,
            })
          }
          size="sm"
        >
          Add transaction
        </Button>
      )}
      <Title className="flex-row flex space-x-2 items-center">
        <div className="flex-row flex space-x-2">Transactions</div>
        <div className="flex-row flex space-x-2">
          {(kyt?.last_30_days_transactions_by_status
            ?.delayed_transactions_count ?? 0) !== 0 && (
            <Badge className="bg-yellow-500">
              {
                kyt?.last_30_days_transactions_by_status
                  ?.delayed_transactions_count
              }{" "}
              Pending
            </Badge>
          )}
        </div>
      </Title>
      <div className="mt-4" />
      <KytTransactionsListFilter
        value={advancedQuery}
        onChange={(value) =>
          setAdvancedQuery({
            ...advancedQuery,
            ..._.omit(value, "on_page", "per_page"),
          })
        }
      />
      <Table
        rowIndex="external_id"
        page={
          1 +
          Math.ceil(
            (advancedQuery?.options?.offset || 0) /
              (advancedQuery?.options?.limit || 1)
          )
        }
        total={transactions?.total ?? 0}
        initialPagination={{
          page: 1,
          perPage: advancedQuery.options.limit ?? 10,
          orderBy: columns.indexOf(advancedQuery.options.order_by as string),
          order: advancedQuery.options.order_way,
        }}
        options={{
          exportCsv: hasAccess("KYT_MANAGE")
            ? {
                fetchData: async (params: Pagination) => {
                  const result = await fetchTransactions({
                    ...filters,
                    on_page: params.page,
                    per_page: params.perPage,
                  });
                  return result.data;
                },
              }
            : {},
        }}
        data={transactions?.data || []}
        columns={columns.map(
          (col) =>
            renderColumns[col] || {
              title: col,
              orderable: true,
              className: "text-right",
              headClassName: "justify-end",
              thClassName: "w-24",
              render: (row: KytTransaction) => _.get(row, col),
            }
        )}
        onRequestData={async ({ page, perPage, orderBy, order }) => {
          setAdvancedQuery({
            ...advancedQuery,
            options: {
              ...advancedQuery.options,
              offset: (page - 1) * perPage,
              limit: perPage,
              order_by: columns[orderBy!] || undefined,
              order_way: order ?? "ASC",
            },
          });
        }}
        loading={loading}
      />
    </Page>
  );
}
