import { delayRequest } from "@features/utils";
import { LoadingAtom } from "@features/utils/loading";
import { sortBy } from "lodash";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { KytApiClient } from "../api-client/api-client";
import { KytTransaction, KytTransactionsRequestOptions } from "../types";
import {
  TransactionFiltersAtom,
  TransactionsListAtom,
  TransactionsQueryInputAtom,
} from "./store";

export const useTransactions = () => {
  const [query, setQuery] = useRecoilState(TransactionsQueryInputAtom);
  const [search, setSearch] = useRecoilState(TransactionFiltersAtom);
  const [, setTransactions] = useRecoilState(TransactionsListAtom);
  const [loading, setLoading] = useRecoilState(LoadingAtom("useTransactions"));

  const schema = useQuery({
    queryKey: ["kyt", "transactions", "schema"],
    queryFn: KytApiClient.getTransactionsSearchSchema,
  });

  const refresh = useCallback(
    async (options?: KytTransactionsRequestOptions) => {
      setLoading(true);
      delayRequest("useTransactionsRefresh", async () => {
        const unordered = await KytApiClient.getTransactions({
          ...(options || search),
        });
        const ordered = sortBy(unordered.data, (item) =>
          new Date(item.date).getTime()
        );
        setTransactions({ ...unordered, data: ordered.reverse() });
        setLoading(false);
      });
    },
    [setTransactions, setLoading]
  );

  const fetchTransactions = async (options: KytTransactionsRequestOptions) => {
    return await KytApiClient.getTransactions({ ...options });
  };

  const newSearchTransactions = useQuery<{
    data: KytTransaction[];
    total: number;
  }>({
    queryKey: ["kyt", "transactions", query.query, query.options],
    queryFn: async () => {
      return await KytApiClient.getTransactionsAdvanced(
        query.query,
        query.options
      );
    },
    cacheTime: 2000,
  });

  return {
    schema,
    transactions: newSearchTransactions?.data,
    search: setSearch,
    setQuery,
    fetchTransactions,
    refresh,
    loading,
  };
};
